import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosClient, {
    getCartStatus,
    getCurrencyLocal,
    GetEventChangedCurrency,
    saveCurrencyLocal,
    savelangLocal,
    SaveToken
} from "./axios-client";
import {toast} from "react-toastify";
import {fetchCreateCart, fetchGetData} from "./Components/Cart/Cart";
import {useDispatch, useSelector} from "react-redux";
import GetMethods from "./Components/GetMethods";
import LocalCart from "./Components/Cart/LocalCart";

const AsUser =()=>{
    const {token} = useParams();
    const translations = useSelector((state) => state.Storetranslate.translations);
    const navigate = useNavigate();
    const { getMethods } = GetMethods();
    const {getCart}=LocalCart();
    const {deleteAllCart}=LocalCart();
    const dispatch = useDispatch();
    const HandelLoginForAdmin =(token)=>{
        axiosClient.post('loginAsOwner',{
            token:token
        })
            .then(response=>{

                const user = response.data.data.user;
                const token = response.data.data.token;
                if(GetEventChangedCurrency() && GetEventChangedCurrency() ===1){
                    axiosClient.post('/update/attribute', {
                        currency: getCurrencyLocal() && getCurrencyLocal().id
                    })
                        .then(response => {
                            sessionStorage.removeItem('EventChangedCurrency');
                        })
                        .catch(error => {
                            if (error?.response?.data?.message) {
                                toast.error('' + error?.response?.data?.message,translations);
                            }
                        });
                }else{
                    saveCurrencyLocal(response.data.data.currency)
                    dispatchEvent(new Event('changedCurrency'));
                }
                SaveToken(token, user);
                savelangLocal(response.data.data.lang);
                dispatchEvent(new Event('LangChanged'));
                if(getCartStatus()){
                    getCart().map((item, index) => {
                        dispatch(fetchCreateCart(item.type, item.cart_id, item.price));
                    });
                    deleteAllCart();
                    dispatch(fetchGetData());
                }
                getMethods();
                if(response.data.data.hasCart === true){
                    localStorage.setItem('haveCart',1);
                    dispatchEvent(new Event('CreateCart'));
                }
                if(user?.hasCard === 1){
                    dispatchEvent(new Event('payAsAuth'));
                }

                sessionStorage.setItem('IsAdmin',1);
                dispatchEvent(new Event('admin'));
                navigate('/${getLocalLang()?.code}/profile');
            })
            .catch(error=>{

            })
    }
    useEffect(()=>{
        if(token){
            HandelLoginForAdmin(token)
        }
    },[token])
}

export default AsUser;