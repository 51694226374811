import { Info, X } from "lucide-react";
import React, { useState, useEffect, useCallback } from "react";
import TranslateComponent from "./TranslateComponent";
import axiosClient from "axios-client";
import { debounce } from "lodash";

const PopupFAQ = React.memo(() => {
  const [faqs, setFaqs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  // Fetch FAQs with debouncing
  const fetchFAQs = useCallback(
    debounce(() => {
      axiosClient
        .get("/qusPayment")
        .then((response) => {
          setFaqs(response.data.data || []);
        })
        .catch((error) => {
          console.error("Error fetching FAQs:", error);
        });
    }, 300),
    []
  );

  useEffect(() => {
    fetchFAQs();
    return () => fetchFAQs.cancel(); // Cleanup debounce on unmount
  }, [fetchFAQs]);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleTogglePopup = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  if (faqs.length === 0) return null; // Don't render if no FAQs are available

  return (
    <div className="h-[20px]">
      <button
        onClick={handleTogglePopup}
        className="text-black hover:text-gray-900 focus:outline-none"
      >
        <Info size={20} />
      </button>

      {isOpen && (
        <div className="absolute right-[5%] top-[80px] bg-white shadow-lg p-4 rounded-md w-max z-[9999] text-white border">
          <h3 className="text-lg font-bold mb-4 text-center">
            <TranslateComponent word={"Frequently Asked Questions"} />
          </h3>
          {faqs.map((faq, index) => (
            <div key={index} className="mb-2">
              <button
                onClick={() => toggleFAQ(index)}
                className="flex gap-x-2 justify-between items-center text-start w-[340px] py-2 px-3 bg-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none"
              >
                <span className="font-semibold">{faq.name}</span>
                <span>{activeIndex === index ? "-" : "+"}</span>
              </button>
              {activeIndex === index && (
                <div
                  className="p-3 bg-blue-50 rounded-b-lg text-gray-700 w-[340px]"
                  dangerouslySetInnerHTML={{ __html: faq.description }}
                ></div>
              )}
            </div>
          ))}

          {/* Close button */}
          <button
            onClick={handleClosePopup}
            className="top-[9px] right-4 p-2 text-gray-500 hover:text-black rounded-full absolute"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
      )}
    </div>
  );
});

export default PopupFAQ;