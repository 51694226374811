import React from "react";

const WhayDonation=({
    title,
    photo,
    description,
})=>{
    return (
        <div
            className="justify-center mt-4 container max-w-7xl w-full py-5 px-5 rounded-2xl bg-neutral-300 max-md:mt-10">

            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col ml-5 w-[63%] max-md:ml-0 max-md:w-full">

                    <div
                        className="flex flex-col grow justify-center self-stretch py-8 tracking-wide text-neutral-900 max-md:mt-10 max-md:max-w-full">

                        <div className="self-start text-3xl font-bold leading-8 text-center max-md:max-w-full">

                            {title}

                        </div>

                        <div
                            className="mt-6 text-base font-medium leading-7 self-start text-ellipsis max-md:max-w-full">
                            <div dangerouslySetInnerHTML={{__html: description}}/>
                        </div>

                    </div>

                </div>
                <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full">

                    <img
                        loading="lazy"
                        srcSet={photo}
                        className="grow w-full aspect-[1.54] max-md:mt-10 max-md:max-w-full rounded-2xl"
                    />

                </div>
            </div>

        </div>

    )
}
export default WhayDonation;