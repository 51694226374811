import React, { useState } from "react";
import { CartIcon, HomeIconFooter, PersonIcon } from "../../Images/svgs";
import { Link } from "react-router-dom";
import { getCurrencyLocal, getToken } from "../../axios-client";
import { toast } from "react-toastify";
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector } from "react-redux";
import PopupCopmponent from "../PaymentSteps/PopupCopmponent";
import PopupForLogin from "../PopupForLogin";
import CustomLink from "../../CustomLink";
const FooterNav = () => {
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const [price, setPrice] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const [pricePay, setPricePay] = useState("");
  const [type, setType] = useState("other");
  const [validationMessage, setValidationMessage] = useState("");
  const [isMsgOpen, setIsMsgOpen] = useState(false);
  const handelChangePrice = (e) => {
    const value = e.target.value;
    // Allow only numbers, decimal point, and empty string
    if (/^\d*\.?\d*$/.test(value)) {
      if (value === "") {
        setValidationMessage("Please enter a number.");
        setIsMsgOpen(true);
      } else {
        const num = parseFloat(value);
        if (isNaN(num)) {
          setValidationMessage("Please enter a valid number.");
          setIsMsgOpen(true);
        } else if (num <= 0) {
          setValidationMessage("The number must be greater than zero.");
          setIsMsgOpen(true);
        } else {
          setValidationMessage("");
          setIsMsgOpen(false);
        }
      }

      setPrice(value);
      setShowPopup(true);
      setType("other");

      localStorage.setItem("price", value);
      dispatchEvent(new Event("priceChanged"));
    } else {
      setValidationMessage("Please enter a valid number.");
      setIsMsgOpen(true);
    }
  };
  const [openPopup, setOpenPopup] = useState(false);
  const handleRemovePopUp = () => setOpenPopup(false);
  const [requestType, setRequestType] = useState(false);
  const forPay = (pricePay) => {
    // eslint-disable-next-line no-mixed-operators
    if ((getToken() && getToken() !== "") || getToken() !== null) {
      showPopup && document.getElementById("my_modal_4_other").showModal();
      if (!pricePay) {
        toast.error(
          translateText("Please enter the donation amount", translations)
        );
      }
    } else {
      setOpenPopup(true);
    }
  };
  window.addEventListener("removeForPayDirect", () => {
    setPrice("");
  });
  return (
    <>
      <div className="donation-bow-fixed">
        <div className={"rounded-lg bg-white border-2 flex w-[40%]"}>
          <input
            className="w-full outline-none rounded-lg text-lg indent-1 text-black "
            type={"number"}
            value={price}
            onChange={handelChangePrice}
            placeholder={translateText("value", translations)}
          />
          <button
            className="bg-blue-700 text-white text-sm py-3 px-2 rounded-lg"
            onClick={() => forPay(price)}
          >
            <TranslateComponent word={"donation"} />
          </button>
        </div>

        <CustomLink
          to={"/"}
          className="btn btn-submit w-[50px] p-3 h-[50px]  border-white border-2 bg-blue-700 rounded-[100%]"
        >
          <HomeIconFooter />
        </CustomLink>
        <div className="form-group">
          <span className="absolute top-0 end-30 hidden  translate-middle badge rounded-pill bg-danger">
            {" "}
            0
          </span>
          <CustomLink to={"/cart"} className="btn btn-card bg-blue-700">
            <CartIcon />
          </CustomLink>
          <CustomLink to={"/profile"} className="btn btn-card bg-blue-700">
            <PersonIcon />
          </CustomLink>
        </div>
      </div>

      {!getToken() && (
        <PopupForLogin
          type={type ?? "campaign"}
          openPopUp={openPopup}
          closePopUp={handleRemovePopUp}
          requestType={requestType}
          setRequestType={setRequestType}
          id={"orphan"}
          adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
          totalPrice={price}
        />
      )}
      {showPopup && (
        <PopupCopmponent
          type={type}
          setShowPopup={setShowPopup}
          subscription={false}
          projectId={"orphan"}
          price={price}
          symbol={getCurrencyLocal() && getCurrencyLocal()?.code}
          handelChangePrice={handelChangePrice}
        />
      )}
    </>
  );
};

export default FooterNav;
