import { CloseIcon, DownArrow, ForSearchIcon, LeftICon } from "../Images/svgs";
import TranslateComponent, { translateText } from "./TranslateComponent";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { feachSerch } from "../Managment/SerchResult";
import axiosClient from "../axios-client";
import { setCrumb } from "../Managment/CrumpRedux";
import SideBar from "./Partials/SideBar";
import ProjectCard from "./Partials/ProjectCard";

const CategoryProject = () => {
  const translations = useSelector((state) => state.Storetranslate.translations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [originalProjects, setOriginalProjects] = useState([]); // Store original order
  const [projects, setProjects] = useState([]);
  const [pageTotal, setPageTotal] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [selectedSort, setSelectedSort] = useState("new"); // Default sort is "new"
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [serchWord, setSerchWord] = useState("");
  const [page, setPage] = useState(1);
  const { slug } = useParams();

  const sortProjects = (projectsToSort, sortType) => {
    const sorted = [...projectsToSort];
    if (sortType === "new") {
      // For "new", most recent first (assuming higher IDs are more recent)
      return sorted.reverse();
    } else if (sortType === "old") {
      // For "old", oldest first (original order)
      return sorted;
    }
    return sorted;
  };

  const fetchData = async (word = "", sort = selectedSort, page = 1) => {
    try {
      const response = await axiosClient.get(`category/project/${slug}`, {
        params: { search: word, page, limit: 5 },
      });
      
      const fetchedProjects = response.data.data.projects;
      setOriginalProjects(fetchedProjects); // Store original order
      
      // Apply current sort to new data
      const sortedProjects = sortProjects(fetchedProjects, sort);
      setProjects(sortedProjects);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(setCrumb(crumps));
  }, []);

  const handelSort = (sortType) => {
    setIsOpen(false);
    setSelectedSort(sortType);
    
    // Apply sorting to current projects
    const sortedProjects = sortProjects(originalProjects, sortType);
    setProjects(sortedProjects);
  };

  const handelWishListEvent = () => {
    dispatch(feachSerch(serchWord, selectedSort));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handelChangeSerchword = (e) => {
    const { value } = e.target;
    setSerchWord(value);
  };

  const clear = () => {
    setSelectedSort("new"); // Reset to default sort
    setSerchWord("");
    // Reapply default sorting
    const sortedProjects = sortProjects(originalProjects, "new");
    setProjects(sortedProjects);
  };

  const handelFiter = (word) => {
    setPage(1);
    fetchData(word, selectedSort, 1);
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(serchWord, selectedSort, nextPage);
  };

  const ShowSome = () => {
    setPage(1);
    fetchData(serchWord, selectedSort, 1);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const crumps = [
    { name: "Projects", link: "" },
    { name: slug, link: "" },
  ];

  return (
    <>
      {projects.length > 0 && (
        <div className="flex flex-col">
          <button
            onClick={handleBack}
            className="flex gap-1 text-base text-zinc-900 mt-10"
          >
            <div className="back_LeftICon">
              <LeftICon />
            </div>

            <div className="mr-2">
              <TranslateComponent word={"Back"} />
            </div>
          </button>
          <div className="mt-7 w-full max-md:max-w-full">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="col-span-3 flex flex-col max-md:mt-6">
                <div className="flex flex-col px-2">
                  <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                    <div className="flex flex-col px-2 pt-4 pb-2 bg-white rounded-2xl w-[350px] place-self-center sm:w-full md:py-5">
                      <div className="flex gap-8 justify-between mx-4 text-xl font-light text-right text-black max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
                        <div className="flex flex-row gap-4 w-full items-center">
                          <div className="w-fit">
                            <ForSearchIcon />
                          </div>
                          <input
                            value={serchWord}
                            onChange={handelChangeSerchword}
                            className="w-full h-full outline-none text-black"
                            placeholder={translateText("Search", translations)}
                          />
                        </div>
                        <button
                          onClick={() => handelFiter(serchWord)}
                          className="w-[70px] md:w-fit md:px-5 py-2.5 text-base font-bold text-center text-white whitespace-nowrap bg-blue-900 rounded-lg shadow-sm"
                        >
                          {translateText("Search", translations)}
                        </button>
                      </div>
                      <div className="flex flex-col justify-center mx-2 mt-4 bg-white bg-opacity-70 max-md:max-w-full">
                        <div className="flex gap-5 justify-between px-1.5 py-1 w-full max-md:flex-wrap max-md:max-w-full">
                          <div className="flex gap-5 text-base font-semibold text-center text-black whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                            <div className="dropdown" ref={dropdownRef}>
                              <div
                                tabIndex={0}
                                role="button"
                                onClick={() => setIsOpen(!isOpen)}
                                className="relative flex gap-2 ltr:flex-row-reverse justify-between px-5 py-2.5 rounded-xl border border-gray-200 border-solid"
                              >
                                <div className="w-6 aspect-square">
                                  <DownArrow />
                                </div>
                                <TranslateComponent word={"Sort"} />
                              </div>
                              {isOpen && (
                                <ul
                                  tabIndex={0}
                                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-full"
                                >
                                  <li>
                                    <button onClick={() => handelSort("new")}>
                                      <TranslateComponent word={"new"} />
                                    </button>
                                  </li>
                                  <li>
                                    <button onClick={() => handelSort("old")}>
                                      <TranslateComponent word={"old"} />
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </div>
                            {selectedSort && (
                              <div className="flex gap-2 justify-between px-5 py-2.5 font-bold items-center bg-amber-300 rounded-xl">
                                <div
                                  role={"button"}
                                  className="w-4 aspect-square"
                                  onClick={() => setSelectedSort("")}
                                >
                                  <CloseIcon />
                                </div>
                                <span className="text-sm">
                                  <TranslateComponent word={"Filter By"} />:{" "}
                                  {selectedSort === "old"
                                    ? translateText(
                                        "the oldest",
                                        translations
                                      )
                                    : translateText(
                                        "the most recent",
                                        translations
                                      )}
                                </span>
                              </div>
                            )}
                          </div>
                          {selectedSort && (
                            <button
                              onClick={clear}
                              className="text-base font-semibold text-right text-zinc-900 px-5 py-2.5 rounded-xl border border-gray-200 border-solid"
                            >
                              <TranslateComponent word={"Delete All"} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-2">
                  <div className="grid grid-cols-1 sm:grid-cols-[0_minmax(auto)] md:grid-cols-1 lg:grid-cols-1 gap-4 mt-5">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5">
                      {projects.length > 0 &&
                        projects.map((project, index) => (
                          <ProjectCard
                            handelWishListEvent={handelWishListEvent}
                            project={project}
                            key={`project_${index}`}
                          />
                        ))}
                      {!projects.length > 0 && "No Projects"}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-5">
                  <div>
                    {currentPage < pageTotal && (
                      <div className="text-center">
                        <button
                          className="border rounded-lg px-5 py-3 mx-4"
                          onClick={loadMore}
                        >
                          <TranslateComponent word={"Show more"} />
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    {page > 1 && (
                      <div className="text-center">
                        <button
                          className="border rounded-lg px-5 py-3"
                          onClick={ShowSome}
                        >
                          <TranslateComponent word={"Show a little"} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-3 md:col-span-1 sm:col-span-1 flex flex-col items-center">
                <SideBar projectShow={false} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryProject;
