import { createSlice } from '@reduxjs/toolkit';
import axiosClient from "../axios-client";
import { debounce } from 'lodash';

const MAX_INDEX = 10;

// Debounced function to update attributes
const debouncedUpdateAttribute = debounce((payload) => {
    axiosClient.post('/update/attribute', payload)
        .catch(error => {
            console.error('Error updating attribute:', error);
        });
}, 300); // 300ms debounce time

const Slice = createSlice({
    name: 'dropdown',
    initialState: {
        showlists: Array.from({ length: MAX_INDEX }, () => false),
        selectedValues: [],
        selectedCurrency: [],
        token: localStorage.getItem('token') || null,
    },
    reducers: {
        toggleDropdown: (state, action) => {
            const { index } = action.payload;
            state.showlists = state.showlists.map((showlist, i) => (i === index ? !showlist : false));
        },
        setSelectedValue: (state, action) => {
            const { index, value, type, code_id, LangIcon_CurValue, code } = action.payload;
            if (type === 'lang') {
                state.selectedValues[index] = value;
                savelangLocal({ name: value, code: code_id, icon: LangIcon_CurValue });
                document.documentElement.setAttribute('dir', getLocalLang()?.code !== 'ar' ? 'ltr' : 'rtl');
                if (state.token) {
                    debouncedUpdateAttribute({ lang: code_id });
                }
            } else if (type === 'currency') {
                state.selectedCurrency[index] = value;
                saveCurrencyLocal({ id: code_id, name: value, value: LangIcon_CurValue, code: code } ?? { id: "1", name: "USD", value: LangIcon_CurValue, code: 'USD' });
                if (state.token) {
                    debouncedUpdateAttribute({ currency: code_id });
                }
            }
        },
        setToken: (state, action) => {
            state.token = action.payload;
            localStorage.setItem('token', action.payload);
        },
        clearToken: (state) => {
            state.token = null;
            localStorage.removeItem('token');
        },
    },
});

export const { toggleDropdown, setSelectedValue, setToken, clearToken } = Slice.actions;

export default Slice.reducer;

// Helper functions
const savelangLocal = (lang) => {
    localStorage.setItem('lang', JSON.stringify(lang));
};

const saveCurrencyLocal = (currency) => {
    localStorage.setItem('currency', JSON.stringify(currency));
};

const getLocalLang = () => {
    const lang = localStorage.getItem('lang');
    return lang ? JSON.parse(lang) : null;
};