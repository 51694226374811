import React from "react";
import TranslateComponent from "./TranslateComponent";

const Donuations =({data})=>{

    return (
        <>
            {data?.icons.length>0 && (
                <div className="flex flex-col whitespace-break-spaces lg:whitespace-nowrap items-center px-5 py-16 w-full text-center bg-white max-md:max-w-full rounded-2xl mt-8">
                    <div className="text-3xl font-bold tracking-wide leading-10 text-center text-black max-md:max-w-full">
                        <TranslateComponent word={'Impact of donors'}/>
                    </div>
                    <div  className="flex justify-center items-center px-16 mt-4 rounded-xl max-md:px-5 max-md:max-w-full">

                        <div className="max-w-full w-[830px]">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">

                                {data?.icons[2] && (
                                    <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                                        <div
                                            className="flex flex-col grow justify-center items-center self-stretch px-5 py-1 max-md:mt-10">
                                            <img
                                                loading="lazy"
                                                src={data?.icons[2]}
                                                className="max-w-full aspect-square w-[106px]"
                                            />
                                            <div className="mt-3 text-3xl font-semibold text-zinc-800">
                                                {data?.numberBenefit}
                                            </div>
                                            <div
                                                className="self-stretch mt-3 text-base font-medium text-zinc-600 ">
                                                <TranslateComponent word={'Number of beneficiaries of the projects'}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {data?.icons[1] && (
                                    <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                                        <div
                                            className="flex flex-col grow justify-center items-center self-stretch px-10 py-1 max-md:px-5 max-md:mt-10">
                                            <img
                                                loading="lazy"
                                                src={data?.icons[1]}
                                                className="max-w-full aspect-square w-[106px]"
                                            />
                                            <div className="mt-3 text-3xl font-semibold text-zinc-800">
                                                {data?.projects}
                                            </div>
                                            <div
                                                className="self-stretch mt-3 text-base font-medium text-zinc-600">
                                                <TranslateComponent word={'Number of projects implemented'}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {data?.icons[0] && (
                                    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                                        <div
                                            className="flex flex-col grow justify-center items-center self-stretch px-1.5 py-1 max-md:mt-10">
                                            <img
                                                loading="lazy"
                                                src={data?.icons[0]}
                                                className="max-w-full aspect-square w-[106px]"
                                            />
                                            <div
                                                className="self-stretch mt-3 text-3xl font-semibold text-zinc-800  text-wrap">
                                                {data?.donations}
                                            </div>
                                            <div className="mt-3 text-base font-medium text-zinc-600 ">
                                                <TranslateComponent word={'Total donations'}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Donuations;