import {useEffect, useState} from "react";
import {getToken} from "../../../axios-client";
import {toast} from "react-toastify";
import {translateText} from "../../TranslateComponent";

const DonationLogic =({
  type,
  desc,
  selectedCountry,
  selectedFile,
  translations,
  setType
})=>{
    const [openPopup, setOpenPopup] = useState(false);
    const handleRemovePopUp = () => setOpenPopup(false);
    const[requestType,setRequestType]=useState(false)
    const [showPopup , setShowPopup] = useState(true);
    const [price ,setPrice]=useState('');
    const forPay=(pricePay)=>{
        if(type){
            sessionStorage.setItem('type_fixed',type);
        }
        if(desc){
            sessionStorage.setItem('desc_fixed',desc)
        }
        if(selectedCountry){
            sessionStorage.setItem('countryId_fixed',selectedCountry?.id)
        }
        if(selectedFile){
            sessionStorage.setItem('file_fixed',selectedFile)
        }
        // eslint-disable-next-line no-mixed-operators
        if(getToken() && getToken()!=='' || getToken() !==null){
            showPopup&&(  document.getElementById(`my_modal_4_${type}`).showModal() )
            if(!pricePay){
                toast.error(translateText('Please enter the donation amount',translations));
            }
        }else{
            setOpenPopup(true);
        }
    }
    const handelChangePrice = (e)=>{
        const {value} = e.target;
        if (value > 0){
            setPrice(value);
            setShowPopup(true);
            setType('subProject');
            localStorage.setItem('price',value);
            dispatchEvent(new Event('priceChanged'));
        }else{
            setPrice('');
        }
    }
    useEffect(() => {
        setType('subProject');
        setPrice(2000)
        localStorage.setItem('price',2000);
        dispatchEvent(new Event('priceChanged'));
    }, []);
    useEffect(() => {
        setShowPopup(true)
    }, [showPopup]);
    window.addEventListener('removeForPayDirect',()=>{
        setShowPopup(false)
    })


    return{

        forPay,
        openPopup,
        handleRemovePopUp,
        requestType,
        setRequestType,
        showPopup,
        setShowPopup,
        price,
        handelChangePrice
    }
}
export default DonationLogic;