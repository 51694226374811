import React from "react";
import { Arrow } from "../../Images/svgs";
import { useMediaQuery } from "react-responsive";
import { getLocalLang } from "axios-client";

const DropDown2 = ({ name, path, links }) => {
  // Use media query to determine if the screen size is within the specified range
  const isSmallLaptops = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1280px)",
  });
  // Conditional class names based on media query
  const navClassName = isSmallLaptops
    ? "!px-1 ltr:!px-0  !text-md ltr:!text-sm relative whitespace-nowrap flex flex-row gap-2 items-center justify-between z-50"
    : "relative whitespace-nowrap flex flex-row gap-2 items-center justify-between z-50 ltr:!px-0"; // Apply styles conditionally

  if (!links || links.length === 0) {
    return (
      <li>
        <a href={path} className="relative flex flex-row gap-2 items-center !rounded-md px-2">
          {name}
        </a>
      </li>
    );
  }

  return (
    <>
      {!getLocalLang() && !getLocalLang()?.code ? (
        <>
          {" "}
          <li
            className={`flex items-center lg:whitespace-normal p-1 font-sans text-sm antialiased font-medium leading-normal gap-x-2 text-blue-gray-900 !rounded-md`}
          >
            <a href={path} className={navClassName}>
              {name}
              <Arrow />
            </a>
            <ul className="relative  flex flex-row gap-2 items-center text-black px-4 py-2 border z-50 border-gray-200 !rounded-md font-medium text-sm dark:text-gray-200 ">
              {links.map((child, index) => (
                <DropDown2
                  key={index}
                  name={child.page ? child.page.name : child.text}
                  path={`${
                    child.page
                      ? child.page.slug
                      : `/ar${child.href}`
                  }`}
                  links={child.children}
                />
              ))}
            </ul>
          </li>
        </>
      ) : (
        <>
          <li
            className={`flex items-center lg:whitespace-normal p-1 font-sans text-sm antialiased font-medium leading-normal gap-x-2 text-blue-gray-900 !rounded-md`}
          >
            <a href={path} className={navClassName}>
              {name}
              <Arrow />
            </a>
            <ul className="relative  flex flex-row gap-2 items-center text-black px-4 py-2 border z-50 border-gray-200 rounded-md font-medium text-sm dark:text-gray-200">
              {links.map((child, index) => (
                <DropDown2
                  key={index}
                  name={child.page ? child.page.name : child.text}
                  path={`${
                    child.page
                      ? child.page.slug
                      : `/${getLocalLang()?.code}${child.href}`
                  }`}
                  links={child.children}
                />
              ))}
            </ul>
          </li>
        </>
      )}
    </>
  );
};

export default DropDown2;
