import React from "react";
import TranslateComponent from "./TranslateComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DonationNow = ({ data, forPay }) => {
  return (
    <>
      <div className={`container max-w-7xl mx-auto h-fit sm:h-auto  `}>
        <div
          className="justify-start rounded-2xl max-md:px-5 mt-14 h-full "
          style={{ backgroundColor: data?.background }}
        >
          <div className="flex gap-5 max-md:flex-col md:gap-0  justify-center md:justify-between h-fit">
            <div className="flex md:px-12 flex-col  w-[57%]  max-md:w-full">
              <div className="flex flex-col justify-center items-start self-stretch my-auto font-medium max-md:mt-10 max-md:max-w-full pb-2 pt-4">
                <div className="text-3xl font-bold  text-white text-ellipsis max-md:max-w-full">
                  {data?.title}
                </div>
                <div />
                <div
                  className="self-stretch text-base my-5 leading-7 font-light text-slate-100 max-md:max-w-full"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                ></div>
                <botton
                  onClick={() => {
                    forPay(data?.price);
                  }}
                  role={"button"}
                  className="justify-center p-3 whitespace-nowrap text-center w-32 font-bold bg-amber-300 rounded-lg shadow-sm leading-[111%] text-neutral-900 "
                >
                  <TranslateComponent word={"Donate now"} />
                </botton>
              </div>
            </div>
            <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full px-4 p-8">
              <LazyLoadImage
                srcSet={data?.photo}
                className="grow w-full aspect-[1.54]  max-md:max-w-full object-contain ltr:lg:left-0"
                alt="beVolunteer"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DonationNow;
