import { Plus } from 'lucide-react';
import { useRef } from 'react';
import TranslateComponent from './TranslateComponent';

const FileUploader = ({ handleFile, text }) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
        event.target.value = '';
    };

    return (
        <div className="flex justify-center items-center">
            <button
                className="flex justify-center items-center gap-1 px-4 py-2 bg-gray-100 text-black rounded-md border hover:bg-gray-300 w-full transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                onClick={handleClick}
            >
                <TranslateComponent word={"Upload File"} />
                <Plus className='w-5 h-5' />
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                className="hidden"
            />
        </div>
    );
};

export default FileUploader;
