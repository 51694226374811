import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Acil_icon,
  CartIcon,
  HeartIcon,
  SuccessIconSmall,
} from "../../Images/svgs";
import { useDispatch } from "react-redux";
import { fetchCreateCart, fetchGetData } from "../Cart/Cart";
import { toast } from "react-toastify";
import LocalCart from "../Cart/LocalCart";
import axiosClient, {
  getCurrencyLocal,
  getLocalLang,
  getToken,
  truncateText,
} from "../../axios-client";
import { withToken } from "../../MiddelwareToken";
import { Fade } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import PopupCopmponent from "../PaymentSteps/PopupCopmponent";
import PopupForLogin from "../PopupForLogin";
import CustomLink from "../../CustomLink";
import { Blocks, ChartBarIcon, EyeOff, HandCoins } from "lucide-react";

// const colorClasses = [
//   "text-pink-800 bg-pink-100 border-pink-800",
//   "text-blue-800 bg-blue-100 border-blue-800",
//   "text-green-800 bg-green-100 border-green-800",
//   "text-yellow-800 bg-yellow-100 border-yellow-800",
// ];

// const getRandomColorClass = () => {
//   const randomIndex = Math.floor(Math.random() * colorClasses.length);
//   return `relative px-2.5 py-1 rounded-xl w-fit border-solid badge ${colorClasses[randomIndex]}`;
// };

const interpolateColor = (percentage) => {
  const clampedPercentage = Math.min(percentage, 100);
  const startColor = [135, 165, 251]; // #87A5FB in RGB
  const endColor = [71, 102, 181]; // #4766B5 in RGB
  const r = Math.round(
    startColor[0] + (endColor[0] - startColor[0]) * (clampedPercentage / 100)
  );
  const g = Math.round(
    startColor[1] + (endColor[1] - startColor[1]) * (clampedPercentage / 100)
  );
  const b = Math.round(
    startColor[2] + (endColor[2] - startColor[2]) * (clampedPercentage / 100)
  );
  return `rgb(${r}, ${g}, ${b})`;
};

const formatNumberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function ProjectCard({
  project,
  index,
  handelWishListEvent,
  handelGetWish,
  className = null,
  hight = null,
  width = "w-auto",
  MobileMap = null,
  marginUnset = null,
  Paytype,
  handelHiddenProjects,
  forHiddenClick,
  isSimilar = false,
}) {
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const [openPopup, setOpenPopup] = useState(false);
  const handleRemovePopUp = () => setOpenPopup(false);
  const [requestType, setRequestType] = useState(false);
  const { createCart } = LocalCart();
  const dispatch = useDispatch();
  const isTabletScreen = useMediaQuery({
    query: "(min-width: 600px) and (max-width: 786px)",
  });
  const updatedGallery = [{ image: project?.main_image }, ...project?.gallery];

  const [price, setPrice] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [type, setType] = useState("");
  const [wishList, setWishList] = useState(project.wishlist);

  const handelCreateCart = async (type, cart_id, price) => {
    if (price) {
      await dispatch(fetchCreateCart(type, cart_id, price));
      await dispatch(fetchGetData());
    } else {
      toast.error(translateText("Enter the amount to donate", translations));
    }
  };

  const handelCreateCartLocal = (type, name, cart_id, price, symbol, image) => {
    if (price) {
      createCart(type, name, cart_id, price, symbol, image);
    } else {
      toast.error(translateText("Enter the amount to donate", translations));
    }
  };

  const handelChangePrice = (e) => {
    const { value } = e.target;
    if (value > 0) {
      setPrice(value);
      setShowPopup(true);
      setType("project");
      localStorage.setItem("price", value);
      localStorage.setItem("ProjectIsZakat", project.zakat);
      dispatchEvent(new Event("priceChanged"));
    } else {
      setPrice("");
    }
  };

  const forPay = (pricePay) => {
    if ((getToken() && getToken() !== "") || getToken() !== null) {
      showPopup &&
        document
          .getElementById(`my_modal_4_${Paytype ? Paytype : "project"}`)
          .showModal();
      if (!pricePay) {
        toast.error(
          translateText("Please enter the donation amount", translations)
        );
      }
    } else {
      setOpenPopup(true);
    }
  };

  const wishlist = (project_id, wishListStatus) => {
    const url = wishListStatus ? "delete" : "create";
    const requestvariable = wishListStatus ? "id" : "project_id";
    axiosClient
      .post(`/wishlist/${url}`, {
        [requestvariable]: project_id,
      })
      .then((response) => {
        if (url === "delete") {
          if (handelGetWish) {
            handelGetWish();
          }
          setWishList(false);
        } else {
          setWishList(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = useNavigate();
  const handelAndCheckWishlist = withToken(wishlist);

  const getByThisCategory = (category_id, type, postType) => {
    localStorage.setItem("category_id_ByCategory", category_id);
    navigate(`/${getLocalLang()?.code}/CategoryProject/${postType}`);
  };

  const goToLogin = () => {
    navigate(`/${getLocalLang()?.code}/login`);
  };

  return (
    <>
      {forHiddenClick && (
        <button
          onClick={handelHiddenProjects}
          className={"p-2"}
          style={{
            fontSize: "16px",
            backgroundColor: "#EAEFFB",
            color: "black",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <EyeOff className="w-4 h-4" />
        </button>
      )}
      <Fade>
        <div
          key={index}
          className={`${className !== null ? className : "my-3"} ${
            !isSimilar && "max-w-[300px] md:max-w-[290px] mx-auto"
          } h-max transform transition duration-300 hover:scale-105 rounded-lg bg-white shadow-lg hover:shadow-xl dark:border-gray-200 mk-rtl`}
        >
          <div
            className={`p-2 space-y-1 ${
              hight ?? "h-max"
            } flex flex-col justify-between`}
          >
            {updatedGallery && (
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className={`mb-1 h-fit md:h-auto ${MobileMap}`}
                containerClass="container"
                dotListClass=""
                draggable={false}
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={true}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable={false}
              >
                {updatedGallery.map((item, index) => (
                  <div
                    key={index}
                    className={`${MobileMap} flex overflow-hidden relative flex-col justify-between self-stretch px-3 pt-3 pb-12 text-sm font-medium tracking-normal leading-3 text-center text-emerald-900 uppercase whitespace-nowrap rounded-md w-full aspect-[1.26]`}
                  >
                    <LazyLoadImage
                      src={item.image ?? project?.main_image}
                      className={`${MobileMap} object-cover absolute inset-0 w-full size-full`}
                      alt={`Image ${index}`}
                    />
                    <div
                      className={`flex relative gap-2 ${marginUnset} justify-between sm:-top-1`}
                    >
                      <span
                        className="relative px-2.5 py-1 rounded-xl w-fit badge border-opacity-50 bg-blue-600 text-white"
                        role={"button"}
                        onClick={() =>
                          getByThisCategory(
                            project.category_id,
                            "project",
                            project.category_slug
                          )
                        }
                      >
                        {project.category}
                      </span>
                      {getToken() ? (
                        <button
                          onClick={() =>
                            handelAndCheckWishlist(project.id, wishList)
                          }
                        >
                          <HeartIcon
                            fill={wishList ? "red" : "#0000004a"}
                            className="w-6 aspect-square"
                          />
                        </button>
                      ) : (
                        <button onClick={goToLogin}>
                          <HeartIcon
                            fill={"#ffffff4a"}
                            className="w-6 aspect-square"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
            <div className="w-full h-full flex flex-col gap-4">
              <div className="px-2 flex flex-col gap-2 text-start">
                <div className="flex gap-2">
                  {project.zakat === 1 && (
                    <div
                      className={"tooltip z-50"}
                      data-tip={translateText("Zakat Campaign", translations)}
                    >
                      <div className="flex justify-center items-center p-[6px] bg-blue-600 rounded-full text-white">
                        <HandCoins className="w-4 h-4" />
                      </div>
                    </div>
                  )}
                  <h5 className="text-lg font-semibold dark:text-black text-black h-14 mb-3">
                    {!Paytype ? (
                      <CustomLink
                        to={`/project/${project.slug}`}
                        onClick={() =>
                          dispatchEvent(new Event("loadBlogPostPage"))
                        }
                      >
                        {project?.name?.length &&
                          (isTabletScreen
                            ? truncateText(
                                project.name,
                                project.name.length > 10
                                  ? 15
                                  : project.name.length
                              )
                            : truncateText(
                                project.name,
                                project.name.length > 35
                                  ? 40
                                  : project.name.length
                              ))}
                      </CustomLink>
                    ) : (
                      project?.name?.length &&
                      (isTabletScreen
                        ? truncateText(
                            project.name,
                            project.name.length > 10 ? 15 : project.name.length
                          )
                        : truncateText(
                            project.name,
                            project.name.length > 35 ? 40 : project.name.length
                          ))
                    )}
                  </h5>
                </div>

                <div className="relative w-full h-2 mt-[-14px]">
                  <progress
                    className="progress progress-primary w-full"
                    value={Math.round(project.percentage)}
                    max="100"
                    style={{
                      backgroundColor: "#e0e7ff",
                      color: interpolateColor(project.percentage),
                    }}
                  ></progress>
                </div>

                <div className="flex flex-row justify-between items-center px-1 mt-2">
                  <span className="flex font-medium text-[14px] mt-1 text-blue-950 gap-1">
                    <div className="flex">

                    <div>{project.symbol}</div>
                    <div>
                      {project.current_value &&
                        formatNumberWithCommas(
                          Math.round(project.current_value)
                        )}
                    </div>
                    </div>
                    <div>
                      <TranslateComponent word={"Raised"} />
                    </div>
                  </span>
                  <span
                    className="font-semibold text-[16px]"
                    style={{
                      color: interpolateColor(project.percentage),
                    }}
                  >
                    {Math.round(project.percentage)}%
                  </span>
                </div>
              </div>

              <div className="flex flex-col mt-[-6px]">
                <div className="flex flex-row items-center gap-1 mx-auto my-auto w-full sm:w-full md:w-full">
                  <div className={"rounded-lg border-2 flex"}>
                    <input
                      className="w-full outline-none text-lg indent-1 m-1"
                      value={price}
                      min={0}
                      type="number"
                      placeholder={translateText("Price", translations)}
                      onChange={handelChangePrice}
                    />

                    <span className="flex items-center rounded rounded-l-none border-0 p-1 font-bold text-grey-100">
                      <button
                        className="bg-blue-700 text-white text-sm py-2 px-4 rounded"
                        onClick={() => forPay(price)}
                      >
                        {translateText("Donation", translations)}
                      </button>
                    </span>
                  </div>

                  <span
                    className="py-3 px-3 rounded-lg border-2"
                    role="button"
                    onClick={() => {
                      if (getToken()) {
                        handelCreateCart("project", project.id, price);
                      } else {
                        handelCreateCartLocal(
                          "project",
                          project.name,
                          project.id,
                          price,
                          project.symbol,
                          project.gallery[0].image
                        );
                      }
                    }}
                  >
                    <CartIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>

      {!getToken() && (
        <PopupForLogin
          type={Paytype ? Paytype : type ?? "project"}
          openPopUp={openPopup}
          closePopUp={handleRemovePopUp}
          requestType={requestType}
          setRequestType={setRequestType}
          id={Paytype ? project?.campaignProjectId : project.id}
          adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
          totalPrice={price}
        />
      )}

      {showPopup && (
        <PopupCopmponent
          type={Paytype ? Paytype : type}
          setShowPopup={setShowPopup}
          projectId={Paytype ? project.campaignProjectId : project.id}
          price={price}
          symbol={project.symbol}
          handelChangePrice={handelChangePrice}
        />
      )}
    </>
  );
}

export default ProjectCard;
