import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector, useDispatch } from "react-redux";
import { featchFooter } from "../../Managment/Footer";
import { toast } from "react-toastify";
import logo from "../../Images/logo.png";
import {
  EmailIcon,
  EmailIconEmail,
  EmailIconNew,
  PhoneIcon,
} from "Images/svgs";
import CustomLink from "CustomLink";
import { getLocalLang } from "../../axios-client";

function Footer() {
  const header = useSelector((state) => state.StoreHeader.Headers);
  const footerData = useSelector((state) => state.StoreFooter.data);
  const isLoading = useSelector((state) => state.StoreFooter.loader);
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [transformedFooter, setTransformedFooter] = useState([]);

  useEffect(() => {
    dispatch(featchFooter());
  }, [dispatch]);

  useEffect(() => {
    // Transform the footer links
    if (footerData?.data?.footer) {
      const transformed = footerData.data.footer.map((section) => {
        return {
          ...section,
          children: section.children.map((child) => {
            const slug = child.page ? child.page.slug : child.href;
            return {
              ...child,
              href: slug.startsWith("/") ? slug : `/${slug}`, // Ensure the href starts with a '/'
            };
          }),
        };
      });
      setTransformedFooter(transformed);
    }
  }, [footerData]);

  if (isLoading) {
    return (
      <div className={"flex justify-center"}>
        <TranslateComponent word={"Loading"} />
      </div>
    );
  }

  if (!footerData) {
    return (
      <div className={"flex justify-center"}>
        <TranslateComponent word={"No data available"} />
      </div>
    );
  }

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (email && email.includes("@")) {
      try {
        const response = await axiosClient.post("/email/save", { email });
        toast.success(translateText("Successfully", translations));
        setEmail("");
      } catch (error) {
        toast.error(translateText("Error", translations));
        console.error("Error:", error);
      }
    } else {
      toast.error(translateText("Enter the email correctly", translations));
    }
  };

  const getHref = (href) => {
    if (!href) return "#";
    if (href.includes("ar/") || href.includes("en/") || href.includes("tr/")) return href;
    return `/${getLocalLang().code}${href}`;
  };

  return (
    <footer
      id="target-dev"
      className="pt-10 bg-neutral-50 border-t border-neutral-200 mt-10"
    >
      <div className="container max-w-7xl mx-auto px-2 xl:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-28 lg:gap-20 items-center">
          <div className="grid gap-6">
            <div className="relative">
              <LazyLoadImage
                src={header.logo_header ?? logo}
                className={"h-[60px]"}
                alt="logo"
              />
            </div>
            <div className="text-black text-sm leading-7 textInLine">
              <TranslateComponent
                word={
                  "Al-Khair Foundation was established in 2003, focuses on aid and development, operates globally, is committed to transparency and sustainability, and participates in development in the Middle East."
                }
              />
            </div>
            <div className="grid grid-cols-1 md:gap-3 sm:grid-cols-[200px_200px] gap-8">
              <div className="grid gap-3 items-center sm:grid-cols-[50px_auto] sm:col-start-2">
                <div className="w-12 h-12 bg-neutral-200 rounded-full grid place-items-center">
                  {/* SVG content here */} <EmailIcon />
                </div>
                <div className="grid gap-0.5">
                  <div className="text-neutral-900 text-[15px] font-normal">
                    <TranslateComponent word={"Email us at"} />
                  </div>
                  <div className="text-neutral-900 text-[15px] font-medium">
                    <a
                      href={`https://mail.google.com/mail/?view=cm&fs=1&to=${footerData.data?.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {footerData.data?.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="grid gap-3 items-center sm:grid-cols-[50px_auto] sm:col-start-1 sm:row-start-1">
                <div className="w-12 h-12 bg-neutral-200 rounded-full grid place-items-center">
                  {/* SVG content here */} <PhoneIcon />
                </div>
                <div className="grid gap-0.5">
                  <div className="text-neutral-900 text-[15px] font-normal justify-self-start">
                    <TranslateComponent word={"Do you have a question"} />
                  </div>
                  <div className="text-neutral-900 text-[15px] font-medium justify-self-start">
                    <a
                      href={`https://wa.me/+${footerData.data?.phone}`}
                      className={"whatsappNUM"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{footerData.data?.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-2xl grid gap-6 items-end">
            <div className="grid gap-6">
              <div className="grid gap-1">
                <div className="text-neutral-900 text-xl font-medium capitalize">
                  <TranslateComponent word={"the news"} />
                </div>
                <div className="text-neutral-900 text-base font-normal">
                  <TranslateComponent
                    word={
                      "Subscribe to the newsletter to receive everything new from us"
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 gap-3 dark:bg-white rounded-lg border-2 items-end lg:px-1">
                <div className="relative col-span-3 md:col-span-5 lg:col-start-1 lg:col-span-4 flex items-center">
                  <div className="w-fit h-fit ml-2 mr-2">
                    {/* SVG content here */} <EmailIconEmail />
                  </div>
                  <input
                    className="rounded-lg pl-2 py-2 placeholder-gray-400 w-full focus:border-0 focus:outline-none"
                    placeholder={translateText(
                      "Please write your email here",
                      translations
                    )}
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  className="text-black text-sm col-span-2 md:col-span-5 md:row-start-2 lg:row-start-auto lg:col-span-1 font-bold bg-[#FFCD50] rounded-lg px-2 py-1.5 m-1"
                  onClick={handleSubmit}
                >
                  <TranslateComponent word={"Subscribe"} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 justify-center w-full gap-6 mt-12 mb-5">
          {transformedFooter.map((section, index) => (
            <ul
              key={index}
              className="col-span-1 text-center md:text-start gap-6"
            >
              <li className="text-neutral-900 text-xl font-medium leading-none tracking-wide mb-3">
                {section.text}
              </li>
              {section.children &&
                section.children.map((child, childIndex) => (

                      
                      // Usage
                      <li key={childIndex} className="text-neutral-900 text-base font-normal my-2">
                        <a href={getHref(child.href)}>{child.text}</a>
                      </li>
                ))}
            </ul>
          ))}
        </div>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 py-5 border-t-2 border-neutral-200 items-center">
          <div className="text-center md:text-start text-neutral-900 text-sm font-normal">
            <TranslateComponent
              word={"All rights reserved to Al-Khair Foundation @2023"}
            />
          </div>
          <div className="flex justify-center md:justify-start items-start gap-10">
            <CustomLink
              target={"_blank"}
              to={"/privacy_policy"}
              className="text-black text-[13px] font-medium"
            >
              <TranslateComponent word={"Individual property rights"} />
            </CustomLink>
            <CustomLink
              target={"_blank"}
              to={"/privacy_policy"}
              className="text-black text-[13px] font-medium"
            >
              <TranslateComponent word={"Usage Policy"} />
            </CustomLink>
          </div>
          <div className="flex justify-center md:justify-end items-center gap-5">
            <div className="text-black text-sm font-normal leading-7">
              <TranslateComponent word={"social networks"} />
            </div>
            <div className="flex gap-5">
              {footerData.data?.social &&
                footerData.data?.social.map((section, index) => (
                  <a key={index} target={"_blank"} href={section.link}>
                    <LazyLoadImage src={section.icon} alt={"icon"} />
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
