import React, { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TranslateComponent from "../TranslateComponent";

import ProjectCard from "./ProjectCard";
import Campaigns from "../Campaigns";

function SideBar({
  post,
  projectId,
  percentage,
  projectShow = false,
  OtherTamplateStauts = false,
}) {
  // Define state variables
  const [project, setProject] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaign_order, setCampaign_order] = useState("");
  const [project_order, setProject_order] = useState("");

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      const url = OtherTamplateStauts ? "sidebar/blog" : "sidebar/post";
      try {
        const response = await axiosClient.get(url);
   
        const { project, campaign, media } = response.data.data;
        setProject(project);
        setCampaign(campaign);
        setMedia(media);
        setCampaign_order(response.data.data.campaign_order);
        setProject_order(response.data.data.project_order);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle loading
  if (loading)
    return (
      <div>
        <TranslateComponent word={"Loading"} />
      </div>
    );
  return (
    <>
      {/* Handle error states , temporary hidden the error  */}
      {error ? (
        <div className="">Error: {error}</div>
      ) : (
        <div className="w-full flex flex-col place-items-center gap-5">
          {/* Media Gallery */}
          {media.map((item, index) => (
            <>
              {!item || !item.link || !item.image ? (
                <></>
              ) : (
                <a href={item.link} className={`order-${item.order_media}`}>
                  <LazyLoadImage
                    key={index}
                    loading="lazy"
                    src={item.image}
                    alt={`media-${index}`}
                    className="w-full rounded-xl object-cover md:mt-0"
                  />
                </a>
              )}
            </>
          ))}
          {campaign && (
            <Campaigns
              project={campaign}
              className={`order-${campaign_order} !mb-[unset]`}
            />
          )}
          {project && (
            <ProjectCard
              project={project}
              className={`order-${project_order} !m-[unset]`}
            />
          )}
        </div>
      )}
    </>
  );
}

export default SideBar;
